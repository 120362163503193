export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;

export const EventType = [
  { id: "nil", value: "No Action" },
  { id: "sunexp", value: "Sun Exposure" },
  { id: "artificial_exposure", value: "Artifical Exposure" },
  { id: "screen_exposure", value: "Screen Exposure" },
];
export const weekDays: Array<string> = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const GENDER = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
];
export const METRIC = [
  { id: 0, value: "Imperial & Conventional (in/ft/lb)" },
  // { id: 1, value: "Metric & SI (cm/km/kg)" },
];
export const IMAGE_FILE_SIZE_LIMIT = 20; //  Mb
export const DEFAULT_LAST_LAT = 42.33;
export const DEFAULT_LAST_LONG = -83;
export const PAGE_RANGE_DISPLAYED = 5;
export const LIST_RECORDS_LIMIT=10;

export const IMAGE_FILE_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".jpg",
  ".JPEG",
  ".PNG",
];
export const MeasureType = [{ id: "g", value: "g" }];
export const INTENSITY = [
  { id: "resting", value: "Resting" },
  { id: "vlight", value: "Very Light" },
  { id: "light", value: "Light" },
  { id: "moderate", value: "Moderate" },
  { id: "heavy", value: "Heavy" },
];

export const DATE_FORMAT = "MM-DD-YYYY";
export const TIME_FORMAT = "hh:mm A";
export const MONTH_YEAR_FORMAT = "MMM YYYY";
export const ION_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const Page = {
  NEW_EVENTS: "new_event",
  NEW_SUBJECTIVE: "new_subjective",
  SUBJECTIVE_HISTORY: "subjective_history",
  EVENT_LIST: "event_list",
  MANAGE_EVENTS: "manage_events",
  MY_INFO: "my_info",
  PROFILE_PROGRESS: "profile_progress",
  NEW_LAB: "new_lab",
  LAB_HISTORY: "lab_history",
  NEW_SOLAR:"new_solar",
  SOLAR_HISTORY:"solar_history"
};

export const SUBJECTIVE_FIELDS = [
  {
    name: "mood",
    label: "Mood",
    ratings: [
      "Miserable",
      "Awful",
      "Bad",
      "Not the Best",
      "OK",
      "Alright",
      "Pretty Good",
      "Good",
      "Great",
      "Fantastic",
    ],
  },
  {
    name: "skin_quality",
    label: "Skin Quality ",
    ratings: [
      "Very Dry and Splotchy",
      "Dry and Poor Color",
      "Poor",
      "Could Be Better",
      "OK",
      "Not Bad",
      "Decent",
      "Nice",
      "Soft",
      "Very Soft and Even Color",
    ],
  },
  {
    name: "clothing_fit",
    label: "Clothing Fit",
    ratings: [
      "Busting Seams",
      "Very Bad Fit",
      "Bad Fit",
      "Poor Fit",
      "OK",
      "Decent Fit",
      "Nice Fit",
      "Good Fit",
      "Very Good Fit",
      "Perfect Fit",
    ],
  },
  {
    name: "hair_nail_quality",
    label: "Hair and Nail Quality",
    ratings: [
      "Very Brittle and Breaking",
      "Rough and Weak",
      "Poor",
      "Could Be Better",
      "OK",
      "Decent",
      "Nice",
      "Good",
      "Very Good",
      "Very Smooth and Strong",
    ],
  },
  {
    name: "recovery",
    label: "Recovery From Exercise",
    ratings: [
      "5.5 Days",
      "5 Days",
      "4.5 Days",
      "4 Days",
      "3.5 Days",
      "3 Days",
      "2.5 Days",
      "2 Days",
      "1.5 Days",
      "1 Day",
    ],
  },
  {
    name: "sleep_quality",
    label: "Sleep Quality ",
    ratings: [
      "Insomniac",
      "Constantly Waking",
      "Trouble Sleeping",
      "Hit or Miss",
      "OK",
      "Not Bad",
      "Decent Sleep",
      "Good Sleep",
      "Great Sleep",
      "Like the Dead",
    ],
  },
  // {
  //   name: "Bloating_Nausea",
  //   label: "Bloating/Nausea",
  //   ratings: [
  //     "No Interest",
  //     "Rarely Interested",
  //     "Interested Monthly",
  //     "Interested Bi-Weekly",
  //     "Interested Weekly",
  //     "Interested Every Few Days",
  //     "Interested Daily",
  //     "Interested Several Times a Day",
  //     "Interested Hourly",
  //     "Always Interested",
  //   ],
  // },
  {
    name: "energy_levels",
    label: "Energy Levels",
    ratings: [
      "Dead",
      "Exhausted",
      "Tired",
      "Somewhat Tired",
      "OK",
      "Decent",
      "Nice",
      "Good",
      "Great",
      "Boing!",
    ],
  },
  {
    name: "libido",
    label: "Libido",
    ratings: [
      "No Interest",
      "Rarely Interested",
      "Interested Monthly",
      "Interested Bi-Weekly",
      "Interested Weekly",
      "Interested Every Few Days",
      "Interested Daily",
      "Interested Several Times a Day",
      "Interested Hourly",
      "Always Interested",
    ],
  },
  {
    name: "hunger_cravings",
    label: "Hunger Cravings",
    ratings: [
      "Always Starving",
      "Hungry Hourly",
      "Craving Snacks Between Meals",
      "Occasional Snack Cravings",
      "OK Waiting for Meals",
      "Mostly Satiated",
      "No Snack Cravings",
      "No Meal Cravings",
      "Skipping Lunch",
      "What Food?",
    ],
  },
  {
    name: "body_heat",
    label: "Body Heat",
    ratings: [
      "Freezing",
      "Very Cold",
      "Cold",
      "Cool",
      "Normal",
      "Warm",
      "Very Warm",
      "Hot",
      "Very Hot",
      "Human Torch",
    ],
  },
  {
    name: "headaches",
    label: "Headaches",
    ratings: [
      "Constant",
      "Several Per Hour",
      "Hourly",
      "Daily",
      "Every Few Days",
      "Weekly",
      "Monthly",
      "Rarely",
      "Long Time Ago",
      "None",
    ],
  },
  // {
  //   name: "bloating_nausea",
  //   label: "Bloating/Nausea",
  //   ratings: [
  //     "Am I Pregnant?",
  //     "Unbearable",
  //     "Constant",
  //     "Always for Hours After Eating",
  //     "Always After Eating",
  //     "After Eating Certain Foods",
  //     "Once Meal a Day",
  //     "Once in a While",
  //     "Maybe a Little",
  //     "None",
  //   ],
  // },
];

export const SUBJECTIVE_INITIAL = {
  mood: 5,
  skin_quality: 5,
  clothing_fit: 5,
  hair_nail_quality: 5,
  sleep_quality: 5,
  Bloating_Nausea: 5,
  recovery: 5,
  energy_levels: 5,
  libido: 5,
  hunger_cravings: 5,
  body_heat: 5,
  headaches: 5,
  bloating_nausea: 5,
  linkedTo: undefined,
};

export const GENDER_TYPE = {
  MALE: "male",
  FEMALE: "female",
};

export const TIME = {
  SUNUP: "sunup",
  SUNSET: "sunset",
  SUND: "sund",
  SUNDE: "sunde",
};

export const LAB_FIELDS = [
  {
    identifier: "hscrp",
    name: "HSCRP",
    metric: "mg/dL",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "t3",
    name: "Free T3",
    metric: "pg/mL",
    standard: "uIU/mL",
    conversion: null,
  },
  {
    identifier: "t4",
    name: "Free T4",
    metric: "ng/dL",
    standard: "ng/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "tsh",
    name: "TSH",
    metric: "uIU/mL",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },
  {
    identifier: "rt3",
    name: "Reverse T3",
    metric: "ng/dL",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },
  {
    identifier: "amsc",
    name: "Salivary Cortisol (AM)",
    metric: "ug/dL",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "pmsc",
    name: "Salivary Cortisol (PM)",
    metric: "ug/dL",
    standard: "mg/dL",
    conversion: "mgdlToMmol",
  },
  {
    identifier: "hdl",
    name: "HDL Level",
    metric: "mg/dL",
    standard: "mmHg",
    conversion: null,
  },
  {
    identifier: "sf",
    name: "Serum Ferritin",
    metric: "ng/dL",
    standard: "mmHg",
    conversion: null,
  },
  {
    identifier: "progesterone",
    name: "Progesterone",
    metric: "pmol/L",
    standard: "ng/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "estradiol",
    name: "Estradiol",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "dhea_s",
    name: "DHEA-S",
    metric: "pmol/L",
    standard: "ug/dL",
    conversion: "ugdlToPmol",
  },
  {
    identifier: "f_testosterone",
    name: "Free Testosterone",
    metric: "nmol/L",
    standard: "ng/dL",
    conversion: "ngdlToNmol",
  },
  {
    identifier: "melatonin_a",
    name: "Melatonin (AM)",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "melatonin_p",
    name: "Melatonin (PM)",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "blood_pressure",
    name: "Blood Pressure",
    metric: "nmol/L",
    standard: "ng/mL",
    conversion: "ngmlToNmol",
  },
];

export const SKIN_TONES = [
  {
    bg_color: "#fee3c6",
    val: 1,
  },
  {
    bg_color: "#fde7ad",
    val: 2,
  },
  {
    bg_color: "#f8d998",
    val: 3,
  },
  {
    bg_color: "#f9d4a0",
    val: 4,
  },
  {
    bg_color: "#ecc091",
    val: 5,
  },
  {
    bg_color: "#f2c280",
    val: 6,
  },
  {
    bg_color: "#d49e7a",
    val: 7,
  },
  {
    bg_color: "#bb6536",
    val: 8,
  },
  {
    bg_color: "#cf965f",
    val: 9,
  },
  {
    bg_color: "#af8a60",
    val: 10,
  },
  {
    bg_color: "#935f37",
    val: 11,
  },
  {
    bg_color: "#733f17",
    val: 12,
  },
  {
    bg_color: "#b36645",
    val: 13,
  },
  {
    bg_color: "#7f4423",
    val: 14,
  },
  {
    bg_color: "#5f3310",
    val: 15,
  },
  {
    bg_color: "#291709",
    val: 16,
  },
];
export const ARTIFICIAL_LIGHT = [
  { type: "LED", value: 0 },
  { type: "CFL", value: 1 },
  { type: "Incandescent", value: 2 },
];
export const LIGHTING_TYPE = [
  { type: "Phone", value: 0 },
  { type: "Tablet", value: 1 },
  { type: "PC", value: 2 },
];

export const SUN_TIME_DIFFERENCE = 1.5;

export const Toggle: Array<string> = [
  "email_toggle",
  "sms_toggle",
  "web_notification_toggle",
];

export const ARTIFICIAL_LIGHT_ITEMS = [
  { label: "long_sleeve_pants", amount: 10 },
  { label: "short_sleeve_pants", amount: 30 },
  { label: "short_sleeve_shorts", amount: 50 },
  { label: "no_shirt_shorts", amount: 70 },
  { label: "bikini", amount: 80 },
];