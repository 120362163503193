import { useState } from "react";
import { IonContent } from "@ionic/react";
import { useTranslation } from "react-i18next";

import NewSolarMaster from "./NewSolarMaster";
import { Page} from "../../constants/commonConstants";
import "./SolarMaster.scss";
import SolarMasterHistory from "./SolarMasterHistory";

const SolarMaster = () => {
  const [tab, SetTab] = useState<string | undefined>(Page.NEW_SOLAR);
  const { t: translation } = useTranslation();
  return (
    <IonContent fullscreen>
      <div className="measurements-page">
        <div className="container">
          <div className="page-inner">
            {/* common-tab start */}
            <div className="common-tab">
              <div className="tab-menu">
                <ul>
                <li>
                    <span
                      className={`  ${
                        tab === Page.NEW_SOLAR ? "active" : ""
                      }`}
                      onClick={() => {
                        SetTab(Page.NEW_SOLAR);
                      }}
                    >
                      {translation("today")}
                    </span>
                  </li>
                  <li>
                    <span
                      className={`  ${
                        tab === Page.SOLAR_HISTORY ? "active" : ""
                      }`}
                      onClick={() => {
                        SetTab(Page.SOLAR_HISTORY);
                      }}
                    >
                      {translation("history")}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="tab-contant">
              {tab === Page.NEW_SOLAR ? (
                  <NewSolarMaster />
                ) : (
                  <SolarMasterHistory />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default SolarMaster;
