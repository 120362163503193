export const ROUTES = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  EVENTS: "/events",
  PROFILE: "/profile",
  LABS: "/labs",
  SUBJECTIVE: "/subjective",
  HELP: "/help",
  ADDSUNEXPOSURE: "/add-sun-exposure",
  SOLARMASTER: "/solar-master",
  NOTIFICATION:"/notification",
  UNAUTHORIZE: "/*",
};
export const DONT_SHOW_AFTER_LOGIN_ROUTES = [ROUTES.LOGIN];

export const USER_ROUTES = [
  ROUTES.DASHBOARD,
  ROUTES.EVENTS,
  ROUTES.PROFILE,
  ROUTES.NOTIFICATION,
  ROUTES.LABS,
  ROUTES.SOLARMASTER,
  ROUTES.SUBJECTIVE,
  ROUTES.HELP,
  ROUTES.ADDSUNEXPOSURE,
];

export default ROUTES;
