import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import CommonButton from "../commonButton/CommonButton";
import config from "../../config/config";

import "./CommonModals.scss";

type Props = {
  confirmText?: string;
  description?: string;
  submitText?: string;
  cancelText?: string;
  isLink?: boolean;
  okayText?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  onOkay?: () => void;
  onSubmit?: () => void;
  loading?: boolean;
  heading?: string;
};

const ConfirmModal: React.FC<Props> = (props) => {
  const { editProfileLink } = config;
  const { t: translate } = useTranslation();
  
  const {
    confirmText = translate("are_you_sure"),
    description,
    okayText,
    isLink,
    submitText = translate("yes"),
    cancelText = translate("no"),
    isOpen = false,
    onClose,
    onOkay,
    onSubmit,
    heading,
    loading,
  } = props;
  return (
    <IonModal
      isOpen={isOpen}
      onClick={onClose}
      backdropDismiss={false}
      className="theme-modal confirm-modal"
    >
      <IonHeader>
        <div className="heading text-center">
          <h2>{heading}</h2>
        </div>
        <div className="modal-closer">
          <IonButton>
            <IonIcon icon={closeOutline} onClick={onClose} />
          </IonButton>
        </div>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="modal-body">
        <p
            className="white text-center"
          // dangerouslySetInnerHTML={{ __html: confirmText as string }}
          >
            {confirmText}
            {isLink ?
              <a
                rel="noreferrer"
                href={editProfileLink}
                target="_blank"
              >
                 {translate("semuto_com") }
              </a>
              : null}
          </p>

          {description && (
            <p
              className="white text-center"
              dangerouslySetInnerHTML={{ __html: description as string }}
            />
          )}
        </div>
        {okayText ?
          <div className="modal-bottom text-center">
            <CommonButton
              parentClassName="primary-btn"
              onClick={onOkay}
              disabled={loading}
            >
              {translate("okay")} {loading ? <IonSpinner name="dots" /> : null}
            </CommonButton>
          </div>
          :
          <div className="modal-bottom text-center">
            <CommonButton
              parentClassName="primary-btn"
              onClick={onSubmit}
              disabled={loading}
            >
              {submitText} {loading ? <IonSpinner name="dots" /> : null}
            </CommonButton>
            <CommonButton
              parentClassName="grey-btn"
              onClick={onClose}
              disabled={loading}
            >
              {cancelText}
            </CommonButton>
          </div>}
      </IonContent>
    </IonModal>
  );
};

export default ConfirmModal;
