import {
  Route,
  Redirect,
  withRouter,
  useLocation,
  matchPath,
} from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { remove } from "lodash";

import ROUTES, { DONT_SHOW_AFTER_LOGIN_ROUTES } from "../constants/routes";
import { IAuthReducerState } from "../redux/reducers/AuthReducer";
import { RootState } from "../redux/reducers/Index";

function PrivateGuard(props: any) {
  const location = useLocation();
  const authData: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const route = location?.pathname;

  /**
   * Checking whether user is currently logged in or not
   */
  const isLoggedIn = useCallback(
    () => !!localStorage.getItem("__RTA__"),
    [authData]
  );

  useEffect(() => {
    // Scroll the page to top on route change
    window.scrollTo({ top: 1, behavior: "smooth" });
  }, [route]);

  const afterLoginRoutes = remove(
    Object.values(ROUTES),
    (r) => !DONT_SHOW_AFTER_LOGIN_ROUTES.includes(r)
  );

  let returnData;

  if (isLoggedIn()) {
    if (route === "/") {
      returnData = (
        <Redirect
          to={{
            pathname: ROUTES.DASHBOARD,
            state: { from: location },
          }}
        />
      );
    } else if (
      (!DONT_SHOW_AFTER_LOGIN_ROUTES.includes(route) &&
        afterLoginRoutes.includes(route)) ||
      matchPath(route, afterLoginRoutes)
    ) {
      returnData = <Route {...props} exact />;
    } else {
      returnData = (
        <Redirect
          to={{
            pathname: ROUTES.DASHBOARD,
            state: { from: location },
          }}
        />
      );
    }
  } else if (
    DONT_SHOW_AFTER_LOGIN_ROUTES.includes(route) ||
    matchPath(route, DONT_SHOW_AFTER_LOGIN_ROUTES)?.isExact
  ) {
    returnData = <Route {...props} exact />;
  } else {
    returnData = (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          state: { from: location },
        }}
      />
    );
  }

  return returnData;
}

export default withRouter(PrivateGuard);
