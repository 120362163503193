import { AnyAction, Dispatch } from "redux";
import { IProfile } from "../../interfaces/ProfileInterface";
import ActionType from "../../resources/enums";

export const LoginAction =
  (data: IProfile) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: ActionType.LOGIN,
      payload: data,
    });
  };
export const updateAuth =
  (data: unknown) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: ActionType.UPDATE_AUTH,
      payload: data,
    });
  };

  export const notificationCount =
  (data: unknown) => async (dispatch: Dispatch<AnyAction>) => {
    
    dispatch({
      type: ActionType.NOTIFICATION_COUNT,
      payload: data,
    });
  };
