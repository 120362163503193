const EndPoints = {
  auth: {
    LOGIN: "/user/login",
    LOGOUT: "/user/logout",
  },
  user: {
    EVENT_DATA: "/events/getStock",
    GET_PROFILE: "/user/profile",
    GET_MACROS: "/user/macros",
    UPDATE_USER_PROFILE: "/user/profile/update",
    UPDATE_NOTIFICATION_LEVEL:"/user/profile/update/notificationlevel",
    MANAGE_EVENT: "events/getCusto",
    UPDATE_EVENT: "events/updateCustom",
    PROFILE_PROGESS: "/user/profile/progress",
    DELETE_PROFILE_PROGRESS: "/user/profile/progress/delete",
    FILL_DATA: "/solarfill",
    SUN_EXP: "/sunexposure/new",
    GET_SUN_EXP: "/getsunexposure",
    UPDATE_PERCENTAGE: "/sunexposure/update-percentage",
    GET_SOLAR_HISTORY:"/sunexposure/history/:pageNo",
    sendFcmToken: "/save-fcm-token",
    POST_SOCIAL_LOGIN: "user/login-social-media"
  },
  event: {
    MANAGE_EVENT: "events/getCusto",
    UPDATE_EVENT: "events/updateCustom",
    DELETE_EVENTS: "events/deleteCustom",
    EVENT_LIST_BY_ID: "/events/:id/history",
    ADD_NEW_EVENTS: "/events/new",
    MARK_EVENT_COMPLETED: "/events/put",
  },
  lab: {
    ADD_NEW_LABS: "/labs/new",
    UPDATE_LABS: "/labs/update",
    DELETE_LAB: "/labs/delete",
    GET_LABS_HISTORY: "/labs/getHistory/:pageNo",
  },
  subjective: {
    ADD_NEW_SUBJECTIVE: "/subjective/new",
    UPDATE_SUBJECTIVE: "/subjective/update",
    DELETE_SUBJECTIVE: "/subjective/delete",
    GET_SUBJECTIVE_HISTORY: "/subjective/getHistory/:pageNo",
  },
  notification:{
    GET_NOTIFICATION:"/notifications/getnotification/:offset",
    DELETE_NOTIFICATION:"/notifications/delete",
  }
};

export default EndPoints;
