import { Network } from "@capacitor/network";

import axios, { AxiosResponse } from "axios";
import { getAccessToken } from "../helper/storage";

import config from "../config/config";
import { withData, withError } from "./api";
import { ILatLong } from "../interfaces/CommonInterface";

const timeZone = -(new Date().getTimezoneOffset() / 60);
const latLong: ILatLong = {};

if (navigator.geolocation) {
  navigator.geolocation.getCurrentPosition((position) => {
    latLong["lat"] = position.coords.latitude;
    latLong["long"] = position.coords.longitude;
  });
}

export const http = axios.create({
  baseURL: config.baseUrl,
  headers: { "Content-Type": "application/json" },
});

http.interceptors.request.use(async (req: any) => {
  if (getAccessToken) req.headers.authorization = `Bearer ${getAccessToken()}`;

  if (timeZone) req.headers.timezone = timeZone;
  if (latLong.lat) req.headers.lat = latLong.lat;
  if (latLong.long) req.headers.long = latLong.long;

  // Check internet connection before making http request
  const status = await Network.getStatus();
  if (status.connected) {
    return req;
  }
});

http.interceptors.response.use(
  (res) => {
    if (res.data.code_is_expired){
      localStorage.clear();
      window.location.replace("/");
      return;
    }
    
    return withData(res.data) as unknown as AxiosResponse<any>
  },
  (err) => {
    if(err?.response?.data.message==="Unauthenticated."){      
      localStorage.clear();
      window.location.replace("/");
      return;
    }
    withError(err?.response?.data)}
);

export function get<P>(url: string, params?: P): Promise<any> {
  return http({
    method: "get",
    url,
    params,
  });
}

export function post<D, P>(url: string, data?: D, params?: P): any {
  return http({
    method: "post",
    url,
    data,
    params,
  });
}

export function postFile<D, P>(url: string, data: D, params?: P): any {
  return http({
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    url,
    data,
    params,
  });
}

export function put<D, P>(url: string, data: D, params?: P): any {
  return http({
    method: "put",
    url,
    data,
    params,
  });
}

export function patch<D, P>(url: string, data: D, params?: P): any {
  return http({
    method: "patch",
    url,
    data,
    params,
  });
}

export function remove<P>(url: string, params?: P): any {
  return http({
    method: "delete",
    url,
    params,
  });
}
