import moment, { Duration, Moment } from "moment";
import SunCalc from "suncalc";
import {
  DEFAULT_LAST_LAT,
  DEFAULT_LAST_LONG,
  SUN_TIME_DIFFERENCE,
} from "../constants/commonConstants";
import { IEventList } from "../interfaces/EventInterface";
import { IProfile } from "../interfaces/ProfileInterface";
import { IProfileTime } from "../interfaces/UserInterface";
import { IAuthReducerState } from "../redux/reducers/AuthReducer";

class Helper {
  renderSunTimes = (info: IProfile) => {
    let last_lat;
    let last_long;
    let defaulted;

    SunCalc.addTime(
      /* Number */ 30,
      /* riseName */ "vitdstart",
      /* setName */ "vitdend"
    );
	SunCalc.addTime(
      /* Number */ 30,
      /* riseName */ "mel1end",
      /* setName */ "mel2start"
    );
	SunCalc.addTime(
      /* Number */ 15,
      /* riseName */ "mel1start",
      /* setName */ "mel2end"
    );
	SunCalc.addTime(
      /* Number */ 15,
      /* riseName */ "fil1end",
      /* setName */ "fil2start"
    );
	SunCalc.addTime(
      /* Number */ 0,
      /* riseName */ "fil1start",
      /* setName */ "fil2end"
    );

    if (!info?.last_lat || !info?.last_long) {
      last_lat = DEFAULT_LAST_LAT;
      last_long = DEFAULT_LAST_LONG;
      defaulted = true;
    } else {
      last_lat = info.last_lat;
      last_long = info.last_long;
      defaulted = false;
    }
    const times = SunCalc.getTimes(
      new Date(),
      last_lat as number,
      last_long as number
    );
    return { ...times, defaulted };
  };

    /**
     *  google jwt
     */
    parseJwt=(token: string)=> {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            // eslint-disable-next-line
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      const data = JSON.parse(jsonPayload);
      return data;
    }

  /**
   * FUNCTION FOR CALCULATING BODY FAT OF MALE
   */
  // maleBF = (data: IMeasurement, metric: number, height: number) => {
  //   const { waist, neck } = data;
  //   const res =
  //     495 /
  //       (1.0324 -
  //         0.19077 * Math.log10(Number(waist) * metric - Number(neck) * metric) +
  //         0.15456 * Math.log10((Number(height) as number) * metric)) -
  //     450;
  //   const result = res > 0 ? Math.round(res) : 0;
  //   return result;
  // };

  /**
   * FUNCTION FOR CALCULATING BODY FAT OF FEMALE
   */
  // femaleBF = (data: IMeasurement, metric: number, height: number) => {
  //   const { waist, neck, hip } = data;
  //   const res =
  //     495 /
  //       (1.29579 -
  //         0.35004 * Math.log10(waist * metric + hip * metric - neck * metric) +
  //         0.221 * Math.log10((height as number) * metric)) -
  //     450;
  //   const result = res > 0 ? Math.round(res) : 0;
  //   return result;
  // };

  /**
   * Valid Date
   */
  valid = (date: Moment) => {
    return date instanceof moment && !Number.isNaN(date as unknown as number);
  };

  /**
   * Parse Times
   */
  parseTimes = (str: string, profile2?: IProfile | undefined) => {
    const suntimes: IProfileTime = this.renderSunTimes(profile2 as IProfile);
    const tokens: { [key: string]: Date | undefined } = {
      sunup: suntimes?.sunrise,
      sund: suntimes?.vitdstart,
      sunset: suntimes?.sunset,
      sunde: suntimes?.vitdend,
    };
    if (tokens[str]) return moment(tokens[str]).format("h:mm a");
    return moment(str, "hh:mm").format("h:mm a");
  };

  SunCalc = (profile: IProfile) => {
    const Time = this.renderSunTimes(profile);
    return { ...Time };
  };

  /**
   * Get Index Keys
   */

  getIndexKey = (time: Date, keys: number[]) => {
    const timestamp = moment(time, "h:mm a").valueOf();
    let increment = 0;

    while (true) {
      if (keys.indexOf(timestamp + increment) !== -1) increment += 1;
      else break;
    }
    return timestamp + increment;
  };

  /**
   * Get Sorted  to get time in order
   */
  getSorted = (e: any, userData?:IProfile) => {
    let indexing_keys: number[] = [];
    const events: any = {};
    e
      ? Object?.keys(e)?.forEach((val: any) => {
          let event: any = e[val];
          const rest = event.custom || event.stock;

          event = {
            ...event,
            ...rest,
            id: event.id,
          };
          event.time = this.parseTimes(event.time,userData);
          if (!this.valid(moment(event.time, "hh:mm a"))) return;
          if (event.time_to !== null)
            event.time_to = this.parseTimes(event.time_to,userData);
          if (!this.valid(moment(event.time_to, "hh:mm a")))
            event.time_to = null;
          const indexing_key = this.getIndexKey(event.time, indexing_keys);
          indexing_keys.push(indexing_key);
          events[indexing_key] = event;
        })
      : null;
    indexing_keys = indexing_keys.sort((a: number, b: number) => a - b);
    const temp: IEventList[] = [];
    indexing_keys.forEach((key: any) => {
      temp.push(events[key]);
    });
    return temp;
  };

  /**
   * Get getIuTotal  Add SUN EXPOSURE
   */
  getIuTotal1 = (
    startTime: Date,
    endTime: Date,
    userData: IAuthReducerState,
    amount:number
  ) => {
    const start = moment(startTime, "hh:mm a");
    const end = moment(endTime, "hh:mm a");
    let length = moment.duration(start.diff(end)) as Duration;
    length = length.asMinutes() as any;

    const last_lat = userData.authData.last_lat || DEFAULT_LAST_LAT;
    const last_long = userData.authData.last_long || DEFAULT_LAST_LONG;
    const {skin_tone} = userData.authData;
    const skin_tone_mult = (skin_tone as unknown as number + 0.001)/15*5+1;
    const suntimes = SunCalc.getTimes(new Date(), last_lat, last_long) as IProfileTime;

    const sunpos_start = SunCalc.getPosition(
      start as unknown as Date,
      last_lat,
      last_long
    );

    const sunpos_end = SunCalc.getPosition(
      end as unknown as Date,
      last_lat,
      last_long
    );
    const sunpos_solarnoon = SunCalc.getPosition(
      suntimes.solarNoon,
      last_lat,
      last_long
    );

    let iutotal;
    let iurate_start;
    let iurate_end;
    const min_alt = 30 * (Math.PI / 180);

    if (!(start < moment(suntimes.vitdstart,"hh:mm a") && end < moment(suntimes.vitdstart,"hh:mm a")) && !(start > moment(suntimes.vitdend,"hh:mm a") && end > moment(suntimes.vitdend,"hh:mm a"))) {
	    // Sun Exposure Happened Inside of Vitamin D Window
		if(sunpos_start.altitude > min_alt){
		    // Started above 30 deg
			iurate_start = (-201.48 + 7.64 * sunpos_start.altitude * (180 / Math.PI)) / skin_tone_mult;
		} else {
		    // Started before 30 deg
			iurate_start = 0;
		}
		if(sunpos_end.altitude > min_alt){
		    // Ended above 30 deg
			iurate_end = (-201.48 + 7.64 * sunpos_end.altitude * (180 / Math.PI)) / skin_tone_mult;
		}else{
		    // Ended after 30 deg
			iurate_end = 0;
		}
		if ((moment(suntimes.solarNoon,"hh:mm a") > start) && (moment(suntimes.solarNoon,"hh:mm a") < end)) {
		    // Started Before and Ended After Solar Noon - Use Length1 and Length2
			const iurate_solarnoon = (-201.48 + 7.64 * sunpos_solarnoon.altitude * (180 / Math.PI)) / skin_tone_mult;
			// let length1 = (suntimes.solarNoon as unknown as number) - (start as unknown as number);
      let length1 = moment.duration(moment(suntimes.solarNoon,"hh:mm a").diff(start)) as any;
      let length2 = moment.duration(end.diff(moment(suntimes.solarNoon,"hh:mm a"))) as any;

			length1 = length1.asMinutes();
			// let length2 =(end as unknown as number)-(suntimes.solarNoon as unknown as number);
			length2 = length2.asMinutes();
			const iutotals =((Math.abs(iurate_solarnoon - iurate_start) * Number(length1)) / 2 + Math.min(iurate_start, iurate_solarnoon) * (length1 as unknown as number)) * amount / 100;
			const iutotale =((Math.abs(iurate_end - iurate_solarnoon) * Number(length2)) / 2 + Math.min(iurate_solarnoon, iurate_end) * (length2 as unknown as number)) * amount /100;
			iutotal = iutotals + iutotale;
		} else {
		    // Either All Before or All After Solar Noon - Use Length
			iutotal = ((Math.abs(iurate_start - iurate_end) * (length as unknown as number)) / 2 + Math.min(iurate_start, iurate_end) * (length as unknown as number)) * amount / 100;
		}
    } else {
	  // Sun Exposure Happened Outside of Vitamin D Window
      iutotal = 0;
    }

    return Math.round(iutotal);
  };


  getIuTotal = (startTime: Date,
    endTime: Date,
    userData: IAuthReducerState,
    amount:number) =>{
      const start_date = moment(startTime, "hh:mm a") as unknown as Date;
const end_date = moment(endTime, "hh:mm a") as unknown as Date;

const start = moment(start_date);
const end = moment(end_date);

const {skin_tone} = userData.authData;
const skin_tone_mult = (skin_tone as unknown as number + 0.001)/15*5+1;

const last_lat = userData.authData.last_lat || DEFAULT_LAST_LAT;
const last_long = userData.authData.last_long || DEFAULT_LAST_LONG;

const length1 = moment.duration(end.diff(start));
const length = length1.as('minutes');

const min_alt = 30 * (Math.PI / 180);

let iutotal;
let iurate_start;
let iurate_end;

SunCalc.addTime(30,'vitdstart','vitdend');
const suntimes = SunCalc.getTimes(new Date(), last_lat, last_long)  as IProfileTime;

const sunpos_start = SunCalc.getPosition(start_date, last_lat, last_long);
const sunpos_end = SunCalc.getPosition(end_date, last_lat, last_long);
const sunpos_solarnoon = SunCalc.getPosition(suntimes.solarNoon, last_lat, last_long);

if (!(start < moment(suntimes.vitdstart) && end < moment(suntimes.vitdstart)) && !(start > moment(suntimes.vitdend) && end > moment(suntimes.vitdend))) {
	// Sun Exposure Happened Inside of Vitamin D Window
	console.log("Sun Exposure Happened Inside of Vitamin D Window");
	if(sunpos_start.altitude > min_alt){
		// Started above 30 deg or 0.523598 radians
		console.log("Started above 30 deg or 0.523598 radians");
		iurate_start = (-201.48 + 7.64 * sunpos_start.altitude * (180 / Math.PI)) / skin_tone_mult;
	} else {
		// Started below 30 deg or 0.523598 radians
		console.log("Started below 30 deg or 0.523598 radians");
		iurate_start = 0;
	}
	if(sunpos_end.altitude > min_alt){
		// Ended above 30 deg or 0.523598 radians
		console.log("Ended above 30 deg or 0.523598 radians");
		iurate_end = (-201.48 + 7.64 * sunpos_end.altitude * (180 / Math.PI)) / skin_tone_mult;
	}else{
		// Ended below 30 deg or 0.523598 radians
		console.log("Ended below 30 deg or 0.523598 radians");
		iurate_end = 0;
	}
	if ((moment(suntimes.solarNoon) > start) && (moment(suntimes.solarNoon) < end)) {
		// Started Before and Ended After Solar Noon - Use Length1 and Length2
		console.log("Started Before and Ended After Solar Noon - Use Length1 and Length2");
		const iurate_solarnoon = (-201.48 + 7.64 * sunpos_solarnoon.altitude * (180 / Math.PI)) / skin_tone_mult;
		// let length1 = (suntimes.solarNoon as unknown as number) - (start as unknown as number);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		let length1 = moment.duration(moment(suntimes.solarNoon).diff(start));
		let length2 = moment.duration(end.diff(moment(suntimes.solarNoon)));

		length1 = length1.asMinutes() as unknown as Duration;
		// let length2 =(end as unknown as number)-(suntimes.solarNoon as unknown as number);
		length2 = length2.asMinutes() as unknown as Duration;
		const iutotals =((Math.abs(iurate_solarnoon - iurate_start) * (length1 as unknown as number)) / 2 + Math.min(iurate_start, iurate_solarnoon) * (length1 as unknown as number)) * amount / 100;
		const iutotale =((Math.abs(iurate_end - iurate_solarnoon) * (length2 as unknown as number)) / 2 + Math.min(iurate_solarnoon, iurate_end) * (length2 as unknown as number)) * amount /100;
		iutotal = iutotals + iutotale;
	} else {
		// Either All Before or All After Solar Noon - Use Length
		iutotal = ((Math.abs(iurate_start - iurate_end) * (length)) / 2 + Math.min(iurate_start, iurate_end) * (length)) * amount / 100;
	}
} else {
  // Sun Exposure Happened Outside of Vitamin D Window
  console.log("Sun Exposure Happened Outside of Vitamin D Window");
  iutotal = 0;
}

return Math.round(iutotal);
  }
  
  calcFiliggrinTime = (sunrise:Date | undefined, sunset:Date | undefined) => {
    // const sunrise = Suntimes?.sunrise;
    // const sunset = Suntimes?.sunset;
    const sunriseEnd = moment(sunrise).add(SUN_TIME_DIFFERENCE, "h").format("h:mm a");
    const sunsetEnd = moment(sunset).subtract(SUN_TIME_DIFFERENCE, "h").format("h:mm a");


    return [
      `${moment(sunrise).format("h:mm a")} - ${sunriseEnd}`,
      `${sunsetEnd} - ${moment(sunset).format("h:mm a")}`,
    ];
  };

  calcMelatoninTime = (vitDStart:Date | undefined, vitDEnd:Date | undefined) => {
    // const vitDStart = Suntimes?.vitdstart;
    // const vitDEnd = Suntimes?.vitdend;

    const vitDPrev = moment(vitDStart).subtract(SUN_TIME_DIFFERENCE, "h").format("h:mm a");
    const vitNext = moment(vitDEnd).add(SUN_TIME_DIFFERENCE, "h").format("h:mm a");

    return [
      `${vitDPrev} - ${moment(vitDStart).format("h:mm a")}`,
      `${moment(vitDEnd).format("h:mm a")} - ${vitNext}`,
    ];
  };

  getCurrentPercentage = (currentTime:string, dbTimeArray:Array<string>) =>{

    const startTimeFrame = moment(currentTime?.split('-')[0], "hh:mm a") // time frame window
    const endTimeFrame = moment(currentTime?.split('-')[1], "hh:mm a") // time frame window

    let finalPercentage = 0;
    let dbLen = dbTimeArray.length
    dbTimeArray.map((dbTime) => {
      const startTime = moment(dbTime?.split('-')[0], "hh:mma") // Time filled by user from db
      const endTime = moment(dbTime?.split('-')[1], "hh:mma"); // Time filled by user from db
  
      let newStartTime;
      let newEndTime; // Time to calculate percentage on the basis of available window
      
      if ((startTime <= startTimeFrame && endTime <= startTimeFrame) || (startTime >= endTimeFrame && endTime >= endTimeFrame)){
        dbLen-=1;
        return 0;
      }
  
      if (startTime < startTimeFrame)
      newStartTime = moment(startTimeFrame.format('hh:mma'),'hh:mma')
  
      else
      newStartTime = moment(startTime.format('hh:mma'),'hh:mma')
  
      if (endTime < endTimeFrame)
      newEndTime = moment(endTime.format('hh:mma'), 'hh:mma')
  
      else
      newEndTime = moment(endTimeFrame.format('hh:mma'),'hh:mma')
  
      finalPercentage += parseFloat(((((newEndTime.diff(newStartTime,'minutes'))/(endTimeFrame.diff(startTimeFrame,'minutes')))*100))?.toFixed(2)) as unknown as number ?? 0;
      return;
    })

    return dbLen ? (finalPercentage?.toFixed(2) as unknown as number/dbLen).toFixed(2) : 0;

  }

  getSunArc = (currentDate:Date, userData:IAuthReducerState) =>{

    const last_lat = userData.authData.last_lat || DEFAULT_LAST_LAT;
    const last_long = userData.authData.last_long || DEFAULT_LAST_LONG;
    const suntimes = SunCalc.getTimes(new Date(), last_lat, last_long);

    const sunrise = moment(suntimes.sunrise, "hh:mm a");
    const sunset = moment(suntimes.sunset, "hh:mm a");
    const duration = sunset.diff(sunrise,'minutes'); // Difference between sunrise and sunset is calculated in minutes

    const elapsed = moment(currentDate,"hh:mm a").diff(sunrise,"minutes"); // Time elapsed from morning sunrise time

//  Start:- (35, 340) -> Sun arc start position coordinates
//  Mid (Peak):- (425, -50)
//  End:- (815, 340)

    let xval = Math.round(elapsed/duration * 780); // here x coordinates is calculated

    if(xval < 0)
    xval = 0;
    else if (xval > 780)
    xval = 780;

    const yval=340-Math.round(Math.sqrt(152100-(xval-390)*(xval-390)));

    return `${xval+35},${yval}`; // Since initial x-axis coordinates start with 35, we need to add 35 to obtain it's value on Arc.


  }

}
export default new Helper();
