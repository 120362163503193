import { useState } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import ActionType from "../resources/enums";
import ConfirmModal from "./commonModal/ConfirmModal";
import CommonVideoModal from "./commonModal/CommonVideoModal";
import storage from "../utils/storage";
import toast from "../utils/toastsMessage";

import {IUserInterface } from "../interfaces/CommonInterface";

import "./Menu.scss";

interface AppPage {
  url: string;

  title: string;
  onClick?: () => void;
}

const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/dashboard",
  },
  {
    title: "Events",
    url: "/events",
  },
  {
    title: "Profile",
    url: "/profile",
  },

  {
    title: "Solar Master",
    url: "/solar-master",
  },
  {
    title: "Labs",
    url: "/labs",
  },

  {
    title: "Subjective",
    url: "/subjective",
  },
  {
    title: "Help",
    url: "/help",
  },
  {
    title: "How It Works",
    url: "/",
  },
  {
    title: "Logout",
    url: "/",
  },
];

declare global {
  interface Window {
    appHistory: History;
  }
}
interface Props {
  authData: IUserInterface;
}

const Menu: React.FC<Props> = () => {
  const location = useLocation();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [commonvideoModal, setCommonVideoModal] = useState<boolean>(false);
  const { t: translation } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();
  const handleLogOut = async () => {
    await localStorage.clear();
    storage.removeAll();
    await dispatch({
      type: ActionType.LOGOUT,
    });
    setConfirmModal(false);
    history.push("/login");
    toast.success(translation("logout_successfully"));
  };

  const handleNaviaget = async (menu: AppPage) => {
    if (menu.title === "Logout") {
      setConfirmModal(true);
    } else if (menu.title === "How It Works") {
      setCommonVideoModal(true);
    } else {
      history.push(menu.url);
    }
  };

  return (
    <IonMenu type="reveal" contentId="main">
      <IonContent>
        <div className="menu-top">
         <h2>{translation("solar")}<span>{translation("master")}</span> </h2>
        </div>
        <IonList id="inbox-list">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  lines="none"
                  detail={false}
                  onClick={() => handleNaviaget(appPage)}
                >
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>

      <ConfirmModal
        isOpen={confirmModal ? confirmModal : false}
        heading="Logout"
        confirmText={`${translation("are_you_sure_you_want_to_logout")}`}
        cancelText={translation("no")}
        submitText={translation("yes")}
        onClose={() => setConfirmModal(false)}
        onSubmit={() => handleLogOut()}
      />
       <CommonVideoModal
        isOpen={commonvideoModal ? commonvideoModal : false}
        onClose={() => setCommonVideoModal(false)}
      />
    </IonMenu>
  );
};

export default Menu;
