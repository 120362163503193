import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Pagination from "react-js-pagination";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonProgressBar,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import moment from "moment";

import { ISubjectiveHistory } from "../../interfaces/SubjectivesInterface";
import {
  PAGE_RANGE_DISPLAYED,
  // SUN_TIME_DIFFERENCE,
} from "../../constants/commonConstants";

import { IProfile } from "../../interfaces/ProfileInterface";
import { IProfileTime } from "../../interfaces/UserInterface";
import { IAuthReducerState } from "../../redux/reducers/AuthReducer";
import { RootState } from "../../redux/reducers/Index";
import { getSolarHistory, getSunExposure } from "../../services/dashboard";
import helper from "../../utils/helper";

const SolarMasterHistory = () => {
  const { t: translation } = useTranslation();
  const [open, setOpen] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>();
  const [solarData, setSolarData] = useState<ISubjectiveHistory[]>([]);

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { reset } = useForm({});
  const [Suntimes, sunSetTimes] = useState<IProfileTime>();
  const [sunCoordinatesIndex, setSunCoordinatesIndex] = useState<number>(0);
  const [timeArray, setTimeArray] = useState([]);

  const userData: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  const SunCalcTime = async (profile: IProfile) => {
    const Time = helper.renderSunTimes(profile);
    sunSetTimes({ loaded: true, ...Time });
    return { ...Time };
  };

  useEffect(() => {
    const coordinateIndex = assignSunCoordinates();
    setSunCoordinatesIndex(coordinateIndex);
  }, [Suntimes]);

  useEffect(() => {
    SunCalcTime(userData.authData as unknown as IProfile);
  }, [sunCoordinatesIndex]);

  useEffect(() => {
    getSunExpData();
  }, []);

/**
 * The function `getSunExpData` retrieves sun exposure data and sets the time array with the result.
 */
  const getSunExpData = async () => {
    const resultData = await getSunExposure();
    setTimeArray(resultData?.data?.data);
  };

/**
 * The function calculates the current position of the sun based on sunrise and sunset times.
 * @returns The function `assignSunCoordinates` returns the value of the variable `count - 1`.
 */
  const assignSunCoordinates = () => {
    const getCurrentTime = moment();
    const sunRiseTime = moment(Suntimes?.sunrise);
    const sunSetTime = moment(Suntimes?.sunset);
    const diffInMs = sunSetTime.diff(sunRiseTime, "hours");

    const singlePart = diffInMs / 9;

    let count = 0;
    while (count < 9) {
      sunRiseTime.add(singlePart, "h");
      if (
        moment(sunRiseTime).toDate().getTime() >
        getCurrentTime.toDate().getTime()
      ) {
        return count;
      }
      count += 1;
    }
    return count - 1;
  };

  // const calcFiliggrinTime = () => {
  //   const sunrise = Suntimes?.sunrise;
  //   const sunset = Suntimes?.sunset;
  //   const sunriseEnd = moment(sunrise).add(SUN_TIME_DIFFERENCE, "h").format("h:mm a");
  //   const sunsetEnd = moment(sunset).subtract(SUN_TIME_DIFFERENCE, "h").format("h:mm a");

  //   return [
  //     `${moment(sunrise).format("h:mm a")} - ${sunriseEnd}`,
  //     `${sunsetEnd} - ${moment(sunset).format("h:mm a")}`,
  //   ];
  // };

  // const calcMelatoninTime = () => {
  //   const vitDStart = Suntimes?.vitdstart;
  //   const vitDEnd = Suntimes?.vitdend;

  //   const vitDPrev = moment(vitDStart).subtract(SUN_TIME_DIFFERENCE, "h").format("h:mm a");
  //   const vitNext = moment(vitDEnd).add(SUN_TIME_DIFFERENCE, "h").format("h:mm a");

  //   return [
  //     `${vitDPrev} - ${moment(vitDStart).format("h:mm a")}`,
  //     `${moment(vitDEnd).format("h:mm a")} - ${vitNext}`,
  //   ];
  // };

 /**
  * The `getCurrentPercentage` function calculates the percentage of time intervals within a given time
  * frame based on an array of time intervals from a database.
  */
  const getCurrentPercentage = (
    currentTime: string,
    dbTimeArray: Array<string>
  ) => {
    const startTimeFrame = moment(currentTime?.split("-")[0], "hh:mm a"); // time frame window
    const endTimeFrame = moment(currentTime?.split("-")[1], "hh:mm a"); // time frame window

    let finalPercentage = 0;
    let dbLen = dbTimeArray.length;
    dbTimeArray.map((dbTime) => {
      const startTime = moment(dbTime?.split("-")[0], "hh:mma"); // Time filled by user from db
      const endTime = moment(dbTime?.split("-")[1], "hh:mma"); // Time filled by user from db

      let newStartTime;
      let newEndTime; // Time to calculate percentage on the basis of available window

      if (
        (startTime <= startTimeFrame && endTime <= startTimeFrame) ||
        (startTime >= endTimeFrame && endTime >= endTimeFrame)
      ) {
        dbLen -= 1;
        return 0;
      }

      if (startTime < startTimeFrame)
        newStartTime = moment(startTimeFrame.format("hh:mma"), "hh:mma");
      else newStartTime = moment(startTime.format("hh:mma"), "hh:mma");

      if (endTime < endTimeFrame)
        newEndTime = moment(endTime.format("hh:mma"), "hh:mma");
      else newEndTime = moment(endTimeFrame.format("hh:mma"), "hh:mma");

      finalPercentage +=
        (parseFloat(
          (
            (newEndTime.diff(newStartTime, "minutes") /
              endTimeFrame.diff(startTimeFrame, "minutes")) *
            100
          )?.toFixed(2)
        ) as unknown as number) ?? 0;
      return;
    });

    return dbLen
      ? ((finalPercentage?.toFixed(2) as unknown as number) / dbLen)?.toFixed(2)
      : 0;
  };

  /**
   * The function `isInsideCurrentTime` checks if the current time falls within a given time range and
   * returns a corresponding text color and percentage.
   */
  const isInsideCurrentTime = (currentTime: string) => {
    const currentDateStartTime = moment(
      currentTime?.split("-")[0]?.trim(),
      "h:mm a"
    )
      .toDate()
      .getTime();
    const currentDateEndTime = moment(
      currentTime?.split("-")[1]?.trim(),
      "h:mm a"
    )
      .toDate()
      .getTime();

    const currentDate = moment().toDate().getTime();

    // get percentage
    const percentage = getCurrentPercentage(currentTime, timeArray);
    if (currentDate > currentDateStartTime && currentDate > currentDateEndTime)
      return ["grey-text", percentage];

    if (currentDate > currentDateStartTime && currentDate < currentDateEndTime)
      return ["green-text", percentage];

    return ["", percentage];
  };

 
 /**
  * The function `getSolarList` is an asynchronous function that retrieves solar history data 
  */
  const getSolarList = async (e: number) => {
    setDataLoading(true);
    const solarRes = await getSolarHistory(e);
    setLastPage(solarRes?.data?.history?.total);
    setDataLoading(false);
    setSolarData(solarRes?.data?.result);
    reset(solarRes?.data?.result);
    setPageNo(e);
  };

  const openEditPop = (singleSolarData: []) => {
    setTimeArray(singleSolarData);
    setOpen(true);
  };

  useEffect(() => {
    getSolarList(pageNo);
  }, []);

  return (
    <>
      <div className="theme-card">
        <div className="card-mid">
          <div className="theme-table">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    {/* <td>{translation("user_id")}</td> */}
                    <td>{translation("date")}</td>
                  </tr>
                </thead>
                <tbody>
                  {solarData.length ? (
                    solarData.map((solar, index) => (
                      <tr
                        key={index}
                        onClick={() => openEditPop(Object.values(solar)[0])}
                      >
                        <td>{Object.keys(solar)[0]}</td>
                      </tr>
                    ))
                  ) : dataLoading ? (
                    <tr>
                      <td colSpan={2}>
                        <div className="text-center">
                          <IonSpinner name="lines-sharp" />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <div className="text-center table-info">
                          <p>{translation("no_data")}</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="common-pagination">
            <Pagination
              activePage={pageNo as unknown as number}
              itemsCountPerPage={10}
              totalItemsCount={lastPage as number}
              pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
              onChange={(e: number) => {
                if (pageNo !== e && !dataLoading)
                  getSolarList(e as unknown as number);
              }}
            />
          </div>
        </div>
      </div>

      {/* edit modal start */}
      <IonModal
        isOpen={open}
        trigger="open-modal"
        onWillDismiss={() => setOpen(false)}
        className="theme-modal"
      >
        <IonHeader>
          <div className="heading">
            <h2>{translation("edit")}</h2>
          </div>
          <div className="modal-closer">
            <IonButton onClick={() => setOpen(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </div>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className="modal-body">
            <div className="rating-box">
              <div className="status-bars-section">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-md="6">
                      <div className="status-bar-box Filiggrin-bar">
                        <div className="bar-top">
                          <IonLabel>{translation("Filiggrin")}</IonLabel>
                          <IonLabel
                            className={
                              isInsideCurrentTime(
                                `${moment(Suntimes?.fil1start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.fil1end).format(
                                  "h:mm a"
                                )}`
                              )[0] as string | undefined
                            }
                          >
                            {`${moment(Suntimes?.fil1start).format(
                              "h:mm a"
                            )} - ${moment(Suntimes?.fil1end).format("h:mm a")}`}
                          </IonLabel>
                          <IonLabel
                            className={
                              isInsideCurrentTime(
                                `${moment(Suntimes?.fil2start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.fil2end).format(
                                  "h:mm a"
                                )}`
                              )[0] as string | undefined
                            }
                          >
                            {`${moment(Suntimes?.fil2start).format(
                              "h:mm a"
                            )} - ${moment(Suntimes?.fil2end).format("h:mm a")}`}
                          </IonLabel>
                        </div>
                        <div className="bar-mid">
                          <IonProgressBar
                            value={
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.fil1start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.fil1end).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) /
                                200 +
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.fil2start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.fil2end).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) /
                                200
                            }
                          ></IonProgressBar>
                          <span className="status">
                            {(isInsideCurrentTime(
                              `${moment(Suntimes?.fil1start).format(
                                "h:mm a"
                              )} - ${moment(Suntimes?.fil1end).format(
                                "h:mm a"
                              )}`
                            )[1] as number) /
                              2 +
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.fil2start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.fil2end).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) /
                                2}
                            %
                          </span>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="status-bar-box Melatonin-bar">
                        <div className="bar-top">
                          <IonLabel>{translation("Melatonin")}</IonLabel>
                          <IonLabel
                            className={
                              isInsideCurrentTime(
                                `${moment(Suntimes?.mel1start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.mel1end).format(
                                  "h:mm a"
                                )}`
                              )[0] as string | undefined
                            }
                          >
                            {`${moment(Suntimes?.mel1start).format(
                              "h:mm a"
                            )} - ${moment(Suntimes?.mel1end).format("h:mm a")}`}
                          </IonLabel>
                          <IonLabel
                            className={
                              isInsideCurrentTime(
                                `${moment(Suntimes?.mel2start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.mel2end).format(
                                  "h:mm a"
                                )}`
                              )[0] as string | undefined
                            }
                          >
                            {`${moment(Suntimes?.mel2start).format(
                              "h:mm a"
                            )} - ${moment(Suntimes?.mel2end).format("h:mm a")}`}
                          </IonLabel>
                        </div>
                        <div className="bar-mid">
                          <IonProgressBar
                            value={
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.mel1start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.mel1end).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) /
                                200 +
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.mel2start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.mel2end).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) /
                                200
                            }
                          ></IonProgressBar>
                          <span className="status">
                            {(isInsideCurrentTime(
                              `${moment(Suntimes?.mel1start).format(
                                "h:mm a"
                              )} - ${moment(Suntimes?.mel1end).format(
                                "h:mm a"
                              )}`
                            )[1] as number) /
                              2 +
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.mel2start).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.mel2end).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) /
                                2}
                            %
                          </span>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="status-bar-box vitamin-bar">
                        <div className="bar-top">
                          <IonLabel>{translation("vitD")}</IonLabel>
                          <IonLabel
                            className={
                              isInsideCurrentTime(
                                `${moment(Suntimes?.vitdstart).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.vitdend).format(
                                  "h:mm a"
                                )}`
                              )[0] as string | undefined
                            }
                          >
                            {`${moment(Suntimes?.vitdstart).format(
                              "h:mm a"
                            )} - ${moment(Suntimes?.vitdend).format("h:mm a")}`}
                          </IonLabel>
                          {/* <IonLabel>{moment(Suntimes?.vitdend).format('h:mm a')}</IonLabel> */}
                        </div>
                        <div className="bar-mid">
                          <IonProgressBar
                            value={
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.vitdstart).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.vitdend).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) / 100
                            }
                          ></IonProgressBar>
                          <span className="status">
                            {
                              isInsideCurrentTime(
                                `${moment(Suntimes?.vitdstart).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.vitdend).format(
                                  "h:mm a"
                                )}`
                              )[1]
                            }
                            %
                          </span>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="status-bar-box Trans-bar">
                        <div className="bar-top">
                          <IonLabel>{translation("uca")}</IonLabel>
                          <IonLabel
                            className={
                              isInsideCurrentTime(
                                `${moment(Suntimes?.vitdstart).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.vitdend).format(
                                  "h:mm a"
                                )}`
                              )[0] as string | undefined
                            }
                          >
                            {`${moment(Suntimes?.vitdstart).format(
                              "h:mm a"
                            )} - ${moment(Suntimes?.vitdend).format("h:mm a")}`}
                          </IonLabel>
                        </div>
                        <div className="bar-mid">
                          <IonProgressBar
                            value={
                              (isInsideCurrentTime(
                                `${moment(Suntimes?.vitdstart).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.vitdend).format(
                                  "h:mm a"
                                )}`
                              )[1] as number) / 100
                            }
                          ></IonProgressBar>
                          <span className="status">
                            {
                              isInsideCurrentTime(
                                `${moment(Suntimes?.vitdstart).format(
                                  "h:mm a"
                                )} - ${moment(Suntimes?.vitdend).format(
                                  "h:mm a"
                                )}`
                              )[1]
                            }
                            %
                          </span>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>

            <div>
              {/* <CommonButton
                  type="button"
                  parentClassName="danger-btn"
                  disabled={deleteLoading}
                  onClick={async () => {
                    await setConfirmModal(false);
                    setDeleteModel();
                  }}
                >
                  {" "}
                  {translation("delete")}
                  {deleteLoading ? <IonSpinner name="dots" /> : null}
                </CommonButton> */}
            </div>
          </div>
        </IonContent>
      </IonModal>
      {/* Search modal end */}
      {/* <ConfirmModal
        isOpen={confirmModal ? confirmModal : false}
        confirmText={translation("are_you_sure_you_want_to_delete")}
        cancelText={translation("no")}
        submitText={translation("yes")}
        onClose={() => setConfirmModal(false)}
        onSubmit={() => deleteSubjectiveById(startRating?.id as number)}
      /> */}
    </>
  );
};

export default SolarMasterHistory;
